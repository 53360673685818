<template>
  <div class="pa-2">
    <div class="mb-2 d-flex">
      <v-btn icon @click="getDetail(true)" x-small :loading="loading">
        <v-icon>mdi-sync</v-icon>
      </v-btn>
    </div>
    <v-row>
      <v-col md="3">
        <v-sheet color="red darken-1" dark elevation="2" class="pa-4 mb-2" rounded="lg">
          <h4 class="mb-3" style="color: rgba(255, 255, 255, 0.88)">All Data</h4>
          <span class="text-h2 ml-auto">{{ allRecord }}</span>
        </v-sheet>
      </v-col>
      <v-col md="3">
        <v-sheet color="light-blue darken-1" dark elevation="2" class="pa-4 mb-2" rounded="lg">
          <h4 class="mb-3" style="color: rgba(255, 255, 255, 0.88)">Your Record</h4>
          <span class="text-h2 ml-auto">{{ yourRecord }}</span>
        </v-sheet>
      </v-col>
      <v-col md="3">
        <v-sheet color="orange darken-1" dark elevation="2" class="pa-4 mb-2" rounded="lg">
          <h4 class="mb-3" style="color: rgba(255, 255, 255, 0.88)">Waiting Approval</h4>
          <span class="text-h2 ml-auto">{{ waitingApproval }}</span>
        </v-sheet>
      </v-col>
      <v-col md="3">
        <v-sheet color="green darken-4" dark elevation="2" class="pa-4 mb-2" rounded="lg">
          <h4 class="mb-3" style="color: rgba(255, 255, 255, 0.88)">Approved</h4>
          <span class="text-h2 ml-auto">{{ approved }}</span>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  data: () => ({
    allRecord: 0,
    yourRecord: 0,
    waitingApproval: 0,
    approved: 0,
    loading: false,
    notFound: false,
  }),
  methods: {
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;

      const uri = "addendum/summarize";

      if (refresh == true) this.AxiosStorageRemove("GET", uri);

      var config = {
        cacheConfig: !refresh,
      };
      await this.$axios
        .get(uri, config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.yourRecord = this.data_detail.your_record;
          this.waitingApproval = this.data_detail.waiting_approval;
          this.approved = this.data_detail.approved;

          this.allRecord = this.data_detail.all_record;

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>
